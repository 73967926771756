import React from 'react';

import { MenuIcon } from './index';
import LogoImage from '../../logoImage';

import '../menu.css';
import LinkComponent from '../../linkComponent';

type Props = {
  onToggle: () => void;
};

const MobileHeaderMenu: React.FC<Props> = ({ onToggle }: Props) => (
  <div className="absolute top-0 left-0 w-full block md:hidden">
    <div
      className={`mt-5 ml-5 ${
        process.env.GATSBY_DOMAIN_ID === 'rlp' ? 'hidden' : 'inline-block'
      }`}
    >
      <LinkComponent link="/">
        <LogoImage size="mobile" color="white" />
      </LinkComponent>
    </div>
    <div
      className="fixed right-0 top-0 mt-5 mr-5 z-999"
      onClick={onToggle}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onToggle();
        }
      }}
    >
      <MenuIcon color="white" toggled={false} />
    </div>
  </div>
);

export default MobileHeaderMenu;

import React, { useState } from 'react';

import LogoImage from '../../logoImage';
import LinkComponent, { CTAButton } from '../../linkComponent';
import Star from '../../../assets/vectors/star';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { ContentfulMenuItem, Maybe } from '../../../../types/graphql-types';

import '../menu.css';
import SideMenu from '../sideMenu';
import { DESKTOP_NAV_HEIGHT } from '../../../constants';
import { Menu } from '../types';
import { getBaseTextColor } from '../../../utils';
import MobileHeaderMenu from './mobile';

type MenuIconProps = {
  color?: 'black' | 'white';
  toggled: boolean;
};

export const MenuIcon: React.FC<MenuIconProps> = ({
  color,
  toggled,
}: MenuIconProps) => {
  const borderColorStyle = color === 'white' ? 'border-white' : 'border-black';
  const bgColorStyle = color === 'white' ? 'bg-white' : 'bg-black';
  return (
    <div className="menu-icon cursor-pointer w-6" style={{ height: 26 }}>
      {!toggled && (
        <div
          className={`w-full h-full ${borderColorStyle} border-solid relative`}
          style={{
            borderWidth: '5px 0',
          }}
        >
          <div
            className={`${bgColorStyle} w-full absolute transform -translate-y-1/2`}
            style={{ height: 5, top: '50%' }}
          />
        </div>
      )}
      {toggled && (
        <div className="w-full h-full relative">
          <div
            className={`${bgColorStyle} w-full absolute transform -translate-y-1/2 rotate-45`}
            style={{ height: 5, top: '50%' }}
          />
          <div
            className={`${bgColorStyle} w-full absolute transform -translate-y-1/2 -rotate-45`}
            style={{ height: 5, top: '50%' }}
          />
        </div>
      )}
    </div>
  );
};

MenuIcon.defaultProps = {
  color: 'black',
};

type RegularLeftMenuProps = {
  onMenuToggle: () => void;
  toggled: boolean;
  sideMenu?: boolean;
};

export const RegularLeftMenu: React.FC<RegularLeftMenuProps> = ({
  onMenuToggle,
  toggled,
  sideMenu,
}: RegularLeftMenuProps) => (
  <div
    id={`regular-left-menu${sideMenu ? '-side-menu' : ''}`}
    className="h-full left-0 items-center flex flex-row-reverse md:flex-row justify-between md:justify-start"
  >
    <button
      type="button"
      aria-label="Open the menu"
      aria-expanded={toggled}
      tabIndex={0}
      className="focus:outline-none md:mr-5"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onMenuToggle();
        }
      }}
      onClick={onMenuToggle}
    >
      <MenuIcon toggled={toggled} />
    </button>
    <LogoImage />
  </div>
);

RegularLeftMenu.defaultProps = {
  sideMenu: false,
};

export type CTAItem = Maybe<Pick<ContentfulMenuItem, 'text' | 'link'>>;

type CTARightMenuProps = {
  ctaItem?: CTAItem;
  secondaryCtaItem?: CTAItem;
};

export const CTARightMenu: React.FC<CTARightMenuProps> = ({
  ctaItem,
  secondaryCtaItem,
}: CTARightMenuProps) => {
  const secondaryItemTextClass = getBaseTextColor();
  return (
    <div className="cta-right-menu flex items-center">
      {secondaryCtaItem && (
        <div className={`${secondaryItemTextClass} font-medium uppercase`}>
          <LinkComponent link={secondaryCtaItem.link || ''}>
            {process.env.GATSBY_DOMAIN_ID !== 'rlp' && (
              <div className="inline-block mr-1">
                <Star />
              </div>
            )}
            <span className="hover:text-black">{secondaryCtaItem.text}</span>
          </LinkComponent>
        </div>
      )}
      {ctaItem && (
        <CTAButton
          link={ctaItem.link || ''}
          className="ml-5 md:w-72 lg:w-84"
          fixedHeight={DESKTOP_NAV_HEIGHT}
        >
          {ctaItem.text}
        </CTAButton>
      )}
    </div>
  );
};

export type SubMenu = Array<
  Maybe<Pick<ContentfulMenuItem, 'id' | 'text' | 'link' | 'appearsOnHomepage'>>
>;

export type MenuItem = Pick<
  ContentfulMenuItem,
  'id' | 'text' | 'link' | 'appearsOnHomepage'
> & {
  subMenu?: Maybe<SubMenu>;
};

export type MenuItems = Array<Maybe<MenuItem>>;

export type MenuProps = {
  data?: Menu;
  pathname: string;
};

CTARightMenu.defaultProps = {
  ctaItem: undefined,
  secondaryCtaItem: undefined,
};

const HeaderMenu: React.FC<MenuProps> = ({ data, pathname }: MenuProps) => {
  const [menuToggled, setMenuToggled] = useState<boolean>(false);

  const ctaItem =
    data?.ctaItem?.text && data?.ctaItem?.link ? data.ctaItem : undefined;
  const secondaryCtaItem =
    data?.secondaryCtaItem?.text && data?.secondaryCtaItem?.link
      ? data.secondaryCtaItem
      : undefined;

  const hasCtaItems = ctaItem || secondaryCtaItem;
  return (
    <>
      <div className="hidden md:block">
        <div
          className="w-full nav-placeholder"
          style={{ height: DESKTOP_NAV_HEIGHT }}
        />
        <nav
          className="z-999 bg-white w-full shadow-sm fixed"
          style={{ top: -1 }}
        >
          <div className="container mx-auto">
            <div className="flex justify-between items-center">
              <div className="flex self-stretch relative">
                <RegularLeftMenu
                  onMenuToggle={() => setMenuToggled(!menuToggled)}
                  toggled={menuToggled}
                />
              </div>
              {hasCtaItems && (
                <CTARightMenu
                  ctaItem={ctaItem}
                  secondaryCtaItem={secondaryCtaItem}
                />
              )}
            </div>
          </div>
        </nav>
      </div>
      <SideMenu
        toggled={menuToggled}
        onClose={() => setMenuToggled(false)}
        pathname={pathname}
        data={data}
      />
      <MobileHeaderMenu onToggle={() => setMenuToggled(!menuToggled)} />
    </>
  );
};

export default HeaderMenu;

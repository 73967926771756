import React from 'react';

import { isSSR } from '../../../utils';
import { MenuProps, RegularLeftMenu } from '../headerMenu';
import MenuContent from './menuContent';
import { DESKTOP_NAV_HEIGHT } from '../../../constants';

type SideMenuProps = MenuProps & {
  toggled: boolean;
  onClose: () => void;
};

const SideMenu: React.FC<SideMenuProps> = ({
  data,
  pathname,
  toggled,
  onClose,
}: SideMenuProps) => {
  if (!toggled) {
    return null;
  }
  let paddingLeft = 0;
  if (!isSSR) {
    const regularLeftMenuRef = document?.getElementById('regular-left-menu');
    const menuLeft = regularLeftMenuRef?.getBoundingClientRect().left;
    if (typeof menuLeft === 'number') {
      paddingLeft = menuLeft + (window?.pageXOffset || 0);
    }
  }

  const renderRegularLeftMenu = () => (
    <RegularLeftMenu
      onMenuToggle={() => {
        if (toggled) {
          onClose();
        }
      }}
      toggled={toggled}
      sideMenu
    />
  );

  return (
    <>
      <div
        className="z-9999 h-screen fixed left-0 top-0 md:pr-8 bg-white shadow-sm overflow-y-auto w-full md:w-auto"
        style={{ paddingLeft }}
      >
        <div
          className="nav-offset-placeholder relative hidden md:block"
          style={{
            height: DESKTOP_NAV_HEIGHT,
            minHeight: DESKTOP_NAV_HEIGHT,
            top: -1,
          }}
        >
          {renderRegularLeftMenu()}
        </div>
        <div className="nav-offset-placeholder relative pt-5 px-5 block md:hidden">
          {renderRegularLeftMenu()}
        </div>
        <div className="py-10 pl-5 md:pl-0 pr-5">
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <MenuContent pathname={pathname} data={data} />
            </React.Suspense>
          )}
        </div>
      </div>
      <div
        role="button"
        aria-label="Close the menu"
        tabIndex={0}
        className="z-999 fixed left-0 top-0 right-0 bottom-0 bg-black opacity-70 cursor-default"
        onKeyDown={(event) => {
          if (event.key === 'Enter' && toggled) {
            onClose();
          }
        }}
        onClick={() => {
          if (toggled) {
            onClose();
          }
        }}
      />
    </>
  );
};

export default SideMenu;

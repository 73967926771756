import React from 'react';

const Star: React.FC = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.01805 0L11.6449 5.25967L17.4998 6.09945L13.27 10.2099L14.2718 16L9.01805 13.2597L3.78655 16L4.78832 10.2099L0.558594 6.09945L6.41343 5.25967L9.01805 0Z"
      fill="#CF020A"
    />
  </svg>
);

export default Star;

import React from 'react';

import { MenuProps } from '../headerMenu';
import LinkComponent from '../../linkComponent';

const MenuContent: React.FC<MenuProps> = ({ data }: MenuProps) => (
  <div>
    {data?.items?.map((menuItem) => {
      if (!menuItem) {
        return null;
      }
      const { id, text, link, subMenu } = menuItem;
      if (!text) {
        return null;
      }
      return (
        <div key={id} className="uppercase font-medium mb-8">
          {link && (
            <div>
              <LinkComponent link={link}>
                <span className="text-black hover:text-usm-dark-red">
                  {text}
                </span>
              </LinkComponent>
            </div>
          )}
          {!link && subMenu && subMenu.length > 0 && (
            <>
              {text}
              <div className="mt-3">
                {subMenu.map((subMenuItem) => {
                  if (!subMenuItem) {
                    return null;
                  }
                  const {
                    id: subMenuId,
                    text: subMenuText,
                    link: subMenuLink,
                  } = subMenuItem;
                  if (!subMenuText || !subMenuLink) {
                    return null;
                  }

                  return (
                    <div
                      key={subMenuId}
                      className="font-normal normal-case mb-1"
                    >
                      <div>
                        <LinkComponent link={subMenuLink}>
                          <span className="text-black hover:text-usm-dark-red">
                            {subMenuText}
                          </span>
                        </LinkComponent>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      );
    })}
  </div>
);

export default MenuContent;

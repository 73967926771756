export default function sortByPublished(
  a: { createdAtTimestamp: any; publishedTimestamp: any },
  b: { createdAtTimestamp: any; publishedTimestamp: any }
): number {
  const aTimestamp = a.publishedTimestamp
    ? a.publishedTimestamp
    : a.createdAtTimestamp;
  const bTimestamp = b.publishedTimestamp
    ? b.publishedTimestamp
    : b.createdAtTimestamp;

  if (aTimestamp < bTimestamp) {
    return 1;
  }
  return -1;
}

function sortByDate(
  a: { dateTimestamp: any },
  b: { dateTimestamp: any }
): number {
  if (a.dateTimestamp < b.dateTimestamp) {
    return 1;
  }
  return -1;
}

export { sortByPublished, sortByDate };

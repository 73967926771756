import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Header2, Header3 } from './headers';
import LinkComponent, { CTALink } from './linkComponent';
import {
  ContentfulAsset,
  Maybe,
  ContentfulAuthor,
  ContentfulBlogPost,
  ContentfulEvent,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import { sortByDate, sortByPublished } from '../utils/sortByPublished';

type PostImageProps = {
  link: string;
  photoDesktop?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
  photoMobile?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
};

const PostImage: React.FC<PostImageProps> = ({
  link,
  photoDesktop,
  photoMobile,
}: PostImageProps) => (
  <div className="cursor-pointer blog-list-image md:1/2">
    <div className="hidden md:block pr-10">
      {photoDesktop && (
        <LinkComponent className="pr-3" link={link}>
          <GatsbyImage
            alt="TODO: add alt tag"
            image={photoDesktop.gatsbyImageData}
          />
        </LinkComponent>
      )}
    </div>
    <div className="block md:hidden">
      {photoMobile && (
        <LinkComponent link={link}>
          <GatsbyImage
            alt="TODO: add alt tag"
            image={photoMobile.gatsbyImageData}
          />
        </LinkComponent>
      )}
    </div>
  </div>
);

PostImage.defaultProps = {
  photoDesktop: undefined,
  photoMobile: undefined,
};

type PostListProps = {
  postList?: Array<
    Pick<
      ContentfulBlogPost,
      'id' | 'header' | 'slug' | 'published' | 'createdAt'
    > & {
      publishedTimestamp: ContentfulBlogPost['published'];
      createdAtTimestamp: ContentfulBlogPost['createdAt'];
    } & {
      photoDesktop?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
      photoMobile?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
      author?: Maybe<Pick<ContentfulAuthor, 'name'>>;
    }
  >;
  eventList?: Array<
    Pick<ContentfulEvent, 'id' | 'title' | 'slug' | 'date'> & {
      dateTimestamp: ContentfulEvent['date'];
    } & {
      photoDesktop?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
      photoMobile?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
    }
  >;
};

const PostList: React.FC<PostListProps> = ({
  postList,
  eventList,
}: PostListProps) => {
  let sortedPostList;
  if (postList) {
    sortedPostList = postList.sort(sortByPublished);
  }
  let sortedEventList;
  if (eventList) {
    sortedEventList = eventList.sort(sortByDate);
  }
  return (
    <div className="max-w-screen-lg">
      {sortedPostList &&
        sortedPostList.map((post) => (
          <div
            key={post.id}
            className="flex flex-wrap md:flex-nowrap mb-8 md:mb-10 mt-2 pb-2 blog-list-item"
          >
            <PostImage
              link={`/blog/${post.slug}`}
              photoDesktop={post.photoDesktop}
              photoMobile={post.photoMobile}
            />
            <div className="blog-list-text md:w-1/2">
              <div className="blog-list-title mt-1 normal-case md:uppercase cursor-pointer">
                <LinkComponent link={`/blog/${post.slug}`}>
                  <Header2 color="black" className="hidden md:block">
                    {post.header}
                  </Header2>
                  <Header3 color="white" className="block md:hidden mx-5">
                    {post.header}
                  </Header3>
                </LinkComponent>
              </div>
              <div className="inline-block md:hidden mx-5 mt-2">
                <CTALink link={`/blog/${post.slug}`} text="Read More" />
              </div>
              <div className="hidden md:block">
                {post.author && <p className="mt-5">by {post.author?.name}</p>}
                {post.published && <p className="mt-2">{post.published}</p>}
                {!post.published && post.createdAt && (
                  <p className="mt-2">{post.createdAt}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      {sortedEventList &&
        sortedEventList.map((event) => (
          <div
            key={event.id}
            className="flex flex-wrap md:flex-nowrap mb-8 md:mb-10 mt-2 pb-2 blog-list-item"
          >
            <PostImage
              link={`/events/${event.slug}`}
              photoDesktop={event.photoDesktop}
              photoMobile={event.photoMobile}
            />
            <div className="blog-list-text md:w-1/2">
              <div className="blog-list-title mt-1 normal-case md:uppercase cursor-pointer">
                <LinkComponent link={`/events/${event.slug}`}>
                  <Header2 color="black" className="hidden md:block">
                    {event.title}
                  </Header2>
                  <b className="text-white inline-block md:hidden mx-5">
                    {event.date}:
                  </b>
                  <Header3 color="white" className="block md:hidden mx-5">
                    {event.title}
                  </Header3>
                </LinkComponent>
              </div>
              <div className="inline-block md:hidden mx-5 mt-2">
                <CTALink link={`/events/${event.slug}`} text="Read More" />
              </div>
              <div className="hidden md:block">
                {event.date && <p className="mt-2">{event.date}</p>}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

PostList.defaultProps = {
  postList: undefined,
  eventList: undefined,
};

export default PostList;

import * as React from 'react';
import { graphql } from 'gatsby';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { BlogPostsQuery } from '../../types/graphql-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Header1 } from '../components/headers';
import PostList from '../components/postList';

type Props = {
  data: BlogPostsQuery;
};

const Blog: React.FC<Props> = ({ data }: Props) => {
  const blogPosts = data.allContentfulBlogPost.edges.map((edge) => edge.node);
  return (
    <Layout pathname="TODO: figure out pathname">
      <SEO title="Blog Posts" />
      <div className="container mx-auto pt-16">
        <Header1
          className="mx-5 md:mx-0 md:mb-16"
          color="black"
          mobileColorsFlipped
        >
          Latest News
        </Header1>
        <PostList postList={blogPosts} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPosts {
    allContentfulBlogPost(filter: { topic: { topicId: { eq: "news" } } }) {
      edges {
        node {
          id
          header
          slug
          photoDesktop: photo {
            gatsbyImageData(layout: CONSTRAINED, height: 400, width: 750)
          }
          photoMobile: photo {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 1.75
              width: 1280
              cropFocus: CENTER
              placeholder: BLURRED
            )
          }
          author {
            name
          }
          published(formatString: "MMMM Do, YYYY")
          createdAt(formatString: "MMMM Do, YYYY")
          publishedTimestamp: published(formatString: "X")
          createdAtTimestamp: createdAt(formatString: "X")
        }
      }
    }
  }
`;

export default Blog;
